export const api_url = "https://assg-3-am.azurewebsites.net/api"

const api_key = "h3kPgrunkxmoXc10z9JDqqO8fIKNtgPjyxh0pxIOYZ4wAzFulRhVhw=="

export const api_config = {
    headers:{
      'x-functions-key': api_key,
    }
  };

